import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import preval from 'preval.macro';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import 'moment/locale/ru';

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

const element = document.getElementById('app-build-date');

if (element && element.setAttribute) {
    element.setAttribute('content', dateTimeStamp);
}

const root = document.getElementById('root');

if (!isInPdfContext()) {
    import('./App').then(({App}) => {
        const environment = process.env.REACT_APP_SENTRY_ENV;
        Sentry.init({
            dsn: "https://298dc9e4cb1f4f828900a8f424ba5ef6@o1082955.ingest.sentry.io/6092045",
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                new Integrations.BrowserTracing(),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                    maskAllInputs: false
                })
            ],
            tracesSampleRate: 1.0,
            environment: environment,
        });
        ReactDOM.render(<App/>, root);
    })
} else {
    import('./pdf/PdfReport').then(({PdfReport}) => {
        ReactDOM.render(<PdfReport/>, root)
    })
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


function isInPdfContext() {
    return window.location.href.includes("pdf-rendering-mode");
}